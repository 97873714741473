<template>
  <div class="h-screen">
    <div class="flex flex-col items-center justify-center">
      <div
        class="flex w-full content"
        :class="!$isMobile() ? 'h-screen' : ''"
        :style="
          $isMobile() ? 'height: 100vw; margin-bottom: 20px;' : 'height: 80vh; margin-bottom: 40px;'
        "
        v-for="i in list"
        :key="i.no"
        @click="onClickDetail(i)"
      >
        <div class="content-overlay" :style="$isMobile() ? 'opacity: 1;' : ''"></div>
        <div class="content-title" :style="$isMobile() ? 'top: 50%; left: 50%; opacity: 1;' : ''">
          {{ i.name }}
        </div>
        <vue-load-image class="w-full">
          <img
            slot="image"
            v-if="i.thumbnail"
            class="object-cover w-full"
            :class="!$isMobile() ? 'h-screen' : ''"
            :style="$isMobile() ? 'height: 100vw' : ''"
            :src="i.thumbnail"
          />
          <div
            slot="preloader"
            class="flex items-center justify-center w-full h-screen image-loader bg-gray-50"
            title="2"
          ></div>
        </vue-load-image>
      </div>
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <template slot="spinner">
        <div class="flex items-center justify-center mt-4 mb-4 image-loader" title="2">
          <svg
            version="1.1"
            id="loader-1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="40px"
            height="40px"
            viewBox="0 0 50 50"
            style="enable-background: new 0 0 50 50"
            xml:space="preserve"
          >
            <path
              fill="#000"
              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      </template>
      <template slot="no-more"><span></span></template>
    </infinite-loading>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import client from '../api/client';
import { useRouter } from '@/use-router';
import InfiniteLoading from 'vue-infinite-loading';
import VueLoadImage from 'vue-load-image';

export default defineComponent({
  name: 'Press',
  components: {
    InfiniteLoading,
    VueLoadImage,
  },
  setup() {
    const list = ref([]);
    const router = useRouter();
    const offset = ref(0);
    const limit = ref(2);

    function onClickDetail(item) {
      router.push({
        name: 'Detail',
        params: {
          category: 'projects',
          projectCategory: `${item.category}`,
          title: item.name,
        },
      });
    }

    async function infiniteHandler($state) {
      try {
        const result = await client.getList(
          `projects/${router.currentRoute.params.category}`,
          offset.value,
          limit.value
        );
        if (result.data.count !== 0) {
          offset.value += limit.value;
          list.value.push(...result.data.list);
          $state.loaded();
        } else {
          $state.complete();
        }
      } catch (e) {
        $state.error();
      }
    }

    return {
      list,

      onClickDetail,
      infiniteHandler,
    };
  },
});
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  margin: auto;
  overflow: hidden;
  cursor: pointer;
}

.content .content-overlay {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
  opacity: 1;
}

.content-title {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 80%;
  left: 50%;
  opacity: 0;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-bottom: 0.5em;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-title {
  top: 50%;
  left: 50%;
  opacity: 1;
}

svg path,
svg rect {
  fill: #7957d5;
}
</style>
